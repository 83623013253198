.splash-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.splash-screen-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* ************************************************************************ */
.logo-container {
  display: flex;
  flex-direction: column; /* Stack logo and dots */
  align-items: center; /* Center align */

  position: relative; /* Needed for absolute positioning of the logo */

}

.loading-spin-dots {
  display: flex; /* To keep the loading dots in a row or as required */
}

.mobile-label {
  opacity: 0.6;
  font-size: 1.2rem;
  margin-top: 20px; /* Adjust as needed */
  text-align: center; /* Center text */
}

.desktop-label {
  opacity: 0.6;
  font-size: 2rem;
  margin-top: 30px; /*30px; */ /* Adjust as needed */
  text-align: center; /* Center text */
}

/* ************************************************************************ */
.splash-screen-main .loading-dot {
  font-size: 100px;
  margin-top: -80px;
  position: relative;

  animation: runDots 1.2s ease-in-out infinite alternate;
  -webkit-animation: runDots 1.2s ease-in-out infinite alternate;
}
.splash-screen .loading-dot {
  font-size: 100px;
  margin-top: -80px;
  position: relative;

  animation: runDots 1.2s ease-in-out infinite alternate;
  -webkit-animation: runDots 1.2s ease-in-out infinite alternate;
}
@keyframes runDots {
  0% {
    left: -60px;
    color: #eee;
  }
  50% {
    color: #666;
  }
  100% {
    left: 60px;
    color: #eee;
  }
}
@-webkit-keyframes runDots {
  0% {
    left: -60px;
    color: #eee;
  }
  50% {
    color: #666;
  }
  100% {
    left: 60px;
    color: #eee;
  }
}

/* ************************************************************************ */
.splash-screen-main .loading-spin-dot {
  height: 90px; /* ajusta o diametro do circulo - qto maior, menor o circulo */
  font-size: 100px; /* ajusta tamanho da bolinha */
  color: #4dbd74; /*color:skyblue;*/
  margin-top: -90px; /* move o icone pra cima ou pra baixo */
  margin-left: -10px; /*-25px;*/ /*  maiorr numero, move pra esquerda*/
  position: relative;

  animation: runSpinDots 1.5s ease-in-out infinite;
  -webkit-animation: runSpinDots 1s ease-in-out infinite;
}

.splash-screen-main .loading-spin-dot2 {
  height: 90px; /* ajusta o diametro do circulo - qto maior, menor o circulo */
  font-size: 100px; /* ajusta tamanho da bolinha */
  color: #4dbd74; /*color:skyblue;*/
  margin-top: -90px; /* move o icone pra cima ou pra baixo */
  margin-left: -25px;
  position: relative;

  animation: runSpinCounterDots 1.5s ease-out infinite;
  -webkit-animation: runSpinCounterDots 1.5s ease-out infinite;
}

.splash-screen-main .loading-spin-dot3 {
  height: 90px; /* ajusta o diametro do circulo - qto maior, menor o circulo */
  font-size: 100px; /* ajusta tamanho da bolinha */
  color: #4dbd74; /*color:skyblue;*/
  margin-top: -90px; /* move o icone pra cima ou pra baixo */
  margin-left: -25px;
  position: relative;

  animation: runSpinDots 1.5s infinite;
  -webkit-animation: runSpinDots 1.5s infinite;
}

.splash-screen-main .loading-spin-dot4 {
  height: 90px; /* ajusta o diametro do circulo - qto maior, menor o circulo */
  font-size: 100px; /* ajusta tamanho da bolinha */
  color: #4dbd74; /*color:skyblue;*/
  margin-top: -90px; /* move o icone pra cima ou pra baixo */
  margin-left: -25px;
  position: relative;

  animation: runSpinCounterDots 1.6s infinite;
  -webkit-animation: runSpinCounterDots 1.6s infinite;
}

.splash-screen-main .loading-spin-dot5 {
  height: 90px; /* ajusta o diametro do circulo - qto maior, menor o circulo */
  font-size: 100px; /* ajusta tamanho da bolinha */
  color: #4dbd74; /*color:skyblue;*/
  margin-top: -90px; /* move o icone pra cima ou pra baixo */
  margin-left: -25px;
  position: relative;

  animation: runSpinDots 1.3s infinite;
  -webkit-animation: runSpinDots 1.3s infinite;
}

/************/
.splash-screen .loading-spin-dot {
  height: 125px; /* ajusta o diametro do circulo */
  font-size: 100px;
  color: #4dbd74; /*color:skyblue;*/
  margin-top: -90px; /*-80px */
  position: relative;

  animation: runSpinDots 1.2s ease-in-out infinite;
  -webkit-animation: runSpinDots 1.2s ease-in-out infinite;
}
@keyframes runSpinDots {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes runSpinDots {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes runSpinCounterDots {
  0% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-webkit-keyframes runSpinCounterDots {
  0% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}


/* //////////////////////////////////////////////////// */
/* Logo Styles */
.loading-logo {
  width: 80px; /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
  opacity: 60%; /* Initially hidden */
}
